.resume-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background: var(--header-background);
    color: var(--header-text-color);
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border: 1px solid var(--border-color);
    text-align: left;
  }
  
  .resume-header {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 2px solid var(--border-color);
  }
  
  .profile-pic img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
  
  .header-info {
    margin-left: 20px;
    text-align: left;
  }
  
  .header-info h1 {
    margin: 0;
    font-size: 2.5em;
    color: var(--text-color);
  }
  
  .header-info h2 {
    margin: 5px 0;
    font-size: 1.5em;
    color: var(--text-color);
  }
  
  .header-info p {
    margin: 10px 0;
    color: var(--text-color);
  }
  
  .resume-body {
    display: flex;
    margin-top: 20px;
  }
  
  .resume-left {
    width: 35%;
    padding-right: 20px;
    border-right: 2px solid var(--border-color);
    text-align: left;
  }
  
  .resume-right {
    width: 65%;
    padding-left: 20px;
    text-align: left;
  }
  
  section {
    margin-bottom: 20px;
    text-align: left;
  }
  
  section h3 {
    margin-bottom: 10px;
    color: var(--text-color);
    font-size: 1.2em;
    padding-bottom: 5px;
  }
  
  section p, section ul {
    margin: 0 0 10px 0;
    color: var(--text-color);
    text-align: left;
  }
  
  section ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .job {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .job-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .job h4 {
    margin: 0;
    font-size: 1.1em;
    color: var(--text-color);
  }
  
  .job p {
    margin: 5px 0;
  }
  
  .underline {
    width: 100%;
    height: 1px;
    background-color: var(--border-color);
    margin: 10px 0;
  }
  
  .education-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .education-item p {
    margin: 0;
    font-size: 1em;
    color: var(--text-color);
  }
  
  .education-item span {
    font-size: 1em;
    color: var(--text-color);
  }
  
  @media (max-width: 768px) {
    .resume-header, .resume-body {
      flex-direction: column;
      text-align: center;
    }
  
    .profile-pic {
      margin: 0 auto 20px;
    }
  
    .header-info {
      margin: 0;
      text-align: center;
    }
  
    .resume-left, .resume-right {
      width: 100%;
      padding: 0;
      border: none;
      text-align: center;
    }
  
    .job-header, .education-item {
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
    }
  
    .education-item span {
      align-self: flex-end;
    }
  }
  