.toggle-button {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 10px 20px;
    background-color: var(--header-background);
    color: var(--header-text-color);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  body.light-mode .toggle-button {
    background-color: #fff;
    color: #333;
  }
  
  body.dark-mode .toggle-button {
    background-color: #444;
    color: #f4f4f4;
  }
  