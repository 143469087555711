/* Light Theme */
:root {
  --background-color: #f4f4f4;
  --text-color: #333;
  --header-background: #fff;
  --header-text-color: #333;
  --border-color: #ddd;
}

body.light-mode {
  background-color: var(--background-color);
  color: var(--text-color);
}

.resume-container {
  background-color: var(--header-background);
  color: var(--header-text-color);
  border: 1px solid var(--border-color);
}

/* Dark Theme */
body.dark-mode {
  --background-color: #1d1f21;
  --text-color: #c9d1d9;
  --header-background: #2d2f33;
  --header-text-color: #c9d1d9;
  --border-color: #444c56;

  background-color: var(--background-color);
  color: var(--text-color);
}

body.dark-mode .resume-container {
  background-color: var(--header-background);
  color: var(--header-text-color);
  border: 1px solid var(--border-color);
}

/* Toggle Button Styles */
.toggle-button {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 10px 20px;
  background-color: var(--header-background);
  color: var(--header-text-color);
  border: 1px solid var(--border-color);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

body.light-mode .toggle-button {
  background-color: #fff;
  color: #333;
}

body.dark-mode .toggle-button {
  background-color: #444;
  color: #f4f4f4;
}
